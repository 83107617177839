.page_agenda {
    &_filter {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: end;

            @include media-breakpoint-down(md) {
                margin-top: 20px;
            }

        .form-group {
            margin-right: 10px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 5px;
                    }

                input {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                input, select, i {
                    padding: 5px 10px;
                    border: solid 1px $line-color;
                    font-size: 14px;
                }

                select {
                    border-radius: 5px;
                    padding: 7px 10px;
                }

                i {
                    border: solid 1px $primary-color;
                    background-color: $primary-color;
                    padding: 8.5px 10px;
                    color: #fff;
                    margin-left: -5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
        }
    }

    &--row {
        margin-bottom: 30px;
    }

    &--data {
        text-align: center;
        background-color: $primary-color;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #fff;
        padding: 10px;
        position: relative;
        z-index: 1;
        margin-bottom: -1px;
    }

    &--item {
        padding: 40px;
        border: solid 1px $line-color;
        margin-bottom: -1px;

                @include media-breakpoint-down(md) {
                    padding: 20px;
                }

            &:last-child {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
    }

    &--hora {
        padding: 5px 15px;
        margin-bottom: 15px;
        background-color: #{$primary-color}1a;
        display: inline-block;
        border-radius: 100px;
        color: $primary-color;
    }

    &--content {
        padding-left: 10px;
        padding-right: 10px;

            h4 {
                font-weight: normal;
            }
    }

    &--title {
        margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                font-size: 20px;
            }
    }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: $primary-color !important;
}

.daterangepicker td.active:hover {
    color: #000 !important;
}

.daterangepicker td.in-range {
    background-color: #{$primary-color}1a;
    color: #000 !important;
}