.deputado_card {
    padding: 20px;
    border: $line-color solid 1px;
    margin-bottom: 30px;

    p {
        text-align: center;
    }

    &--cargo {
        font-weight: bold;
    }

    &--nome {
        margin-bottom: 5px;
        margin-top: 1rem;
        background-color: $primary-color;
        padding: 5px;

            a {
                color: $light-color;
            }
    }

    &--partido {
        text-transform: uppercase;
        font-size: $small-text;
    }

    &.licenciado {
        .deputado_card--nome {
            background-color: #c30000;
        }
    }
}