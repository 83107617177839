@mixin gradient {
    background: $gradient-first-color;
    background: linear-gradient(90deg, $gradient-first-color 0%, $gradient-last-color 100%);
}

@mixin paragraph {
    font-size: $text-size;
    color: $text-color;
}

@mixin indicators {
    li {
        width: 10px;
        height: 10px;
        background-color: #fff;
        margin-left: 2.5px;
        margin-right: 2.5px;
        border-radius: 100%;

            &:hover {
                cursor: pointer;
            }

            &.active {
                background-color: #000;
            }
    }
}

@mixin font-calc($size) {
    font-size: calc(#{$size} / #{$font-base-size} * 1rem)
}