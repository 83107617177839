.footer {
    padding-top: 30px;
    padding-bottom: 30px;

    ul {
        padding: 0;
        margin: 0;
        
            li {
                list-style: none;
            }
    }

    a {
        color: $text-color;
        font-size: $small-text;
    }

    &_title {
        margin-bottom: 10px;
    }

    &_logo {
        @include media-breakpoint-down(lg) {
            max-width: 265px;
            margin-bottom: 60px;
            margin-top: 30px;
        }
    }

    &_menu {
        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }
    }

    &_social {
        border: 1px solid $line-color;
        padding: 15px;

        li {
            margin: 0 5px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
        }
    }
}

.sub_footer {
    background-color: $primary-color;
    padding-top: 20px;
    padding-bottom: 20px;

    p {
        color: $light-color;
        margin-bottom: 5px;
        font-size: 12px;

            @include media-breakpoint-down(lg) {
                text-align: center;
            }
    }

    &--address {
        p {
            @include media-breakpoint-up(lg) {
                text-align: right;
            }
        }
    }
}

.copyright {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: $small-text;
    font-size: 10px;
}