// colors
$primary-color: #46794A;
$gradient-first-color: #46794A;
$gradient-last-color: #28822f;
$text-color: #333333;
$line-color: #DADADA;
$light-color: #FFFFFF;


// fonts
$font-base-size: 16;
$text-size: calc(#{$font-base-size} / #{$font-base-size} * 1rem);
$title-size: calc(24 / #{$font-base-size} * 1rem);
$secondary-text: calc(18 / #{$font-base-size} * 1rem);
$small-text: calc(14 / #{$font-base-size} * 1rem);
$tiny-text: calc(12 / #{$font-base-size} * 1rem);