body {
    font-family: 'Roboto', sans-serif;
}

p {
    @include paragraph;
}

a {
    text-decoration: none;
    transition: all .2s .2s;
}

img {
    max-width: 100%;
}

main {
    padding-top: 40px;
    padding-bottom: 40px;
}

.container {
    @include media-breakpoint-down(md) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.btn {
    background-color: $primary-color;
    color: $light-color;
    font-size: $small-text;
    font-weight: 400;
    border-radius: 5px;
    padding: 7px 15px;
    border: none;

        &:hover {
            color: $light-color;
        }
}

.pagination {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-top: 40px;
    padding: 0;

        li {
            list-style: none;
            margin: 0 5px;
            
                a {
                    color: $text-color;
                    transition: all ease-in-out .2s;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;

                        &:hover {
                            background-color: $primary-color;
                            color: $light-color;
                            transition: all ease-in-out .2s;
                        }
                }
        }

        .active {
            background-color: $primary-color;
            color: $light-color;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
        }
}

.search {
    display: flex;
    flex-direction: row;
    justify-content: end;

            @include media-breakpoint-down(md) {
                justify-content: left;
                margin-top: 20px;
            }

        img {
            height: 36px;
            border: 1px solid $line-color;
            border-right: 0;
            padding: 7px 0 7px 10px;
            border-radius: 5px 0 0 5px;
        }
        
        input {
            border: 1px solid $line-color;
            border-left: none;
            padding: 5px 10px;
            border-radius: 0 5px 5px 0;
            color: $text-color;

                &::placeholder {
                    color: $line-color;
                    font-size: 14px;
                }
        }

        button {
            margin-top: -3px;
            margin-left: 5px;
        }
}

.page_top {
    margin-bottom: 60px;

        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }
}

.badges {
    background-color: $line-color;
    display: inline-flex;
    padding: 10px 20px 10px 10px;
    align-items: center;
    max-width: 350px;
    margin-top: -10px;
    margin-bottom: 30px;

        &--text {
            margin: 0 0 0 10px;
            padding: 0;
        }
}

.section_titles {
    font-size: $secondary-text;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: bold;
}

.ver_mais {
    color: $text-color;

        &:hover {
            color: $text-color;
        }
}