.page_header {
    position: relative;
    z-index: 9;

    li > a {
        color: $light-color;
    }

    &--top {
        padding: 20px 0;
        background-color: $primary-color;
        background-image: url('../img/header-fachada.png');
        background-position: center top;
        background-size: cover;
        position: relative;
    
                @include media-breakpoint-up(md) {
                    height: 140px;
                }
    
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include gradient;
                z-index: 0;
                mix-blend-mode: multiply;
            }
    
            .container {
                z-index: 1;
                position: relative;
            }
    
            ul {
                padding: 0;
                margin: 0;
            }
    
            li {
                list-style: none;
            }
    }

    &--logo {
        max-width: 380px;
    }

    &--info {
        text-align: right;
    }

    &--links {
        padding-bottom: 10px !important;

        a {
            font-size: $secondary-text;
            margin-bottom: 10px;
        }
    }

    &--social {
        li {
            margin: 0 5px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
        }
    }

    &--navigation {
        background-color: $primary-color;
        @include gradient;
    }
}

.navbar_link {
    display: flex;
    margin: 0;
    padding: 0;

        li {
            list-style: none;
        }

        &--item {
            position: relative;
            margin: 0 40px;
            font-size: $secondary-text;
            padding: 15px 0;

                &:first-child {
                    margin-left: 0;
                }
                
                &:last-child {
                    margin-right: 0;
                }
        }

        &--parent {
            &:hover {
                > .navbar_link--child {
                    visibility: visible;
                    left: 0;
                    transition: all .5 ease-out;
                    opacity: 1;
                }
            }

            > .navbar_link--child > .navbar_link--parent {
                margin-right: -20px;
                width: 130%;

                    &::before {
                        content: url('../img/ion_arrow-forward.svg');
                        margin: 0 20px 0 -20px;
                        display: block;
                        height: 18px;
                        width: 18px;
                    }

                    > .navbar_link--child {
                        left: 100%;
                        margin-left: 20px;
                        margin-top: -16px;
                    }

            }

            &:last-child {
                > .navbar_link--child > .navbar_link--parent {
                    width: 130%;
                    justify-content: end;
                    margin-right: 0;
                    margin-left: -58px;
                    padding-left: 34px;

                    &::before {
                        margin: 0;
                    }

                    &:hover {
                        &::before {
                            content: url('../img/ion_arrow-backward-black.svg');
                        }
                    }

                    > .navbar_link--child {
                        left: calc(-100% - 40px);
                        margin-right: 20px;
                        margin-top: -15px;
                    }
                }
            }
        }

        &--child {
            visibility: hidden;
            position: absolute;
            border-radius: 4px;
            border: 0.5px $text-color solid;
            background-color: $light-color;
            min-width: 230px;
            max-width: 230px;
            margin-top: 15px;
            padding: 0;
            transition: all .5s ease-in;
            opacity: 0;
            padding: 15px 20px;

                li {
                    margin-top: 15px;
                    margin-bottom: 15px;
                    display: flex !important;
                    flex-direction: row-reverse;
                    flex-wrap: wrap;
                    justify-content: start;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                    a {
                        color: $text-color;
                        font-size: $small-text;
                        line-height: 1.4;
                        width: 80%;
                    }
                }
                
        }
}

.navbar_mobile {
    button {
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
    }

    &--content {
        background-color: $primary-color;
        width: 80%;
        max-width: 306px;
    }

    ul {
        list-style-type: none;
        padding: 40px;
        margin: 0;
        -webkit-overflow-scrolling: touch;
    }

    ul > li {
        display: block;
    }

    ul > li > a {
        color: inherit;
        display: block;
        text-decoration: none;
        transition: all 150ms;
        color: $light-color;
        padding: 12px 0px;
        font-size: $secondary-text;
    }

    .submenu {
        background-color: $primary-color;
        left: 100%;
    }

    .submenu-header > a {
        color: inherit;
        display: block;
    }

    .has-submenu ul li a {
        font-size: $text-size;
    }
}