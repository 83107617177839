.login {
    display: flex;
    height: 100vh;
    flex-wrap: wrap;

            @include media-breakpoint-down(md) {
                background-image: url('../img/login_bg.jpg');
                background-position: center;
                background-size: cover;
                align-items: center;
                justify-content: center;
                flex-direction: column-reverse;

                    &::after {
                        position: absolute;
                        background-color: $primary-color;
                        mix-blend-mode: multiply;
                        width: 100%;
                        height: 100%;
                        content: '';
                        display: block;
                        top: 0;
                        left: 0;
                    }
            }

        &_left {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

                    @include media-breakpoint-down(md) {
                        min-width: 100%;
                        position: relative;
                        z-index: 1;
                        height: 80vh;
                        align-items: flex-start;
                        padding-top: 40px;
                    }

                h1 {
                    margin-bottom: 35px;

                        @include media-breakpoint-down(md) {
                            color: $light-color;
                        }
                }

                label {
                    margin-bottom: 5px;
                    font-weight: 500;

                        @include media-breakpoint-down(md) {
                            color: $light-color;
                        }
                }

                input {
                    margin-bottom: 15px;
                    border-color: #8C8C8C;
                }

                button {
                    width: 100%;
                    padding-top: 15px;
                    padding-bottom: 15px;

                    @include media-breakpoint-down(md) {
                        background-color: $light-color;
                        color: $primary-color;
                    }
                }
        }

        &_right {
            width: 50%;
            background-image: url('../img/login_bg.jpg');
            background-size: cover;
            background-position: right;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

                    @include media-breakpoint-down(md) {
                        min-width: 100%;
                        background-image: none;
                        height: 20vh;
                    }

                    @include media-breakpoint-up(md) {
                        &::after {
                            position: absolute;
                            background-color: $primary-color;
                            mix-blend-mode: multiply;
                            width: 100%;
                            height: 100%;
                            content: '';
                            display: block;
                            top: 0;
                            left: 0;
                        }
                    }


                img {
                    position: relative;
                    z-index: 1;

                        @include media-breakpoint-down(md) {
                            max-width: 250px;
                            margin-bottom: 50px;
                        }
                }
        }
}