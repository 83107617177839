.noticias {
    &_info {
        display: flex;
    }

    &_categoria {
        color: $primary-color;
        margin-right: 10px;
        cursor: pointer;

            &:hover {
                color: $primary-color;
            }
    }

    &_title {
        margin-top: 15px;
        color: $text-color;
    }

    &_destaque {
        img {
            width: 100%;
        }

        .noticias_title {
            font-size: $title-size;
        }

        .noticias_desc {
            font-size: $secondary-text;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &--image {
            @include media-breakpoint-down(lg) {
                margin-bottom: 15px;
            }
        }
    }

    &_item {
        margin-top: 35px;

            &--image {
                @include media-breakpoint-down(md) {
                    margin-bottom: 15px;
                }
            }
    }

    &_desc {
        margin-top: 18px;
        font-size: $small-text;
    }

    .pagination {
        margin-bottom: 40px;
    }
}

.noticias_interna{
    &--title {
        @include font-calc(36);

            @include media-breakpoint-down(md) {
                font-size: $title-size;
            }
    }
}

.noticias_content {
    &--destacada {
        margin-bottom: 20px;
        margin-top: 40px;
        width: 100%;
    }

    &--postimage {
        margin: 30px auto 0 auto;
        display: block;
    }

    &--legendimage {
        font-style: italic;
        font-size: $tiny-text;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    &--arquivos {
        margin-top: 40px;
    }
}

.noticias_footer {
    margin-top: 40px;

        &--tags {
            a {
                margin-right: 10px;
                margin-bottom: 10px;
                display: block;
                color: $text-color;
            }
        }
}

.noticias_galeria {
    margin-top: 40px;
    margin-bottom: 70px;
}

.noticias_outras {
    &--post {
        img {
            margin-bottom: 15px;
            height: 268px;
        }
    }
}