.main_page {
    &--title {
        font-size: $title-size;
        margin-bottom: 60px;

            @include media-breakpoint-down(lg) {
                margin-bottom: 30px;   
            }
    }

    &--content,
    &--editais,
    &--images {
        margin-top: 60px;

            @include media-breakpoint-down(lg) {
                margin-top: 30px;   
            }
    }

    &--images {
        img {
            width: 100%;

                @include media-breakpoint-down(lg) {
                    margin-bottom: 30px;
                }
        }
    }
}

.page_share {
    p {
        margin-bottom: 5px;
    }

    ul {
        padding: 0;
        margin: 0;

            @include media-breakpoint-down(lg) {
                margin-bottom: 30px;
            }
    }

    li {
        list-style: none;
        margin: 0 3px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
    }
}