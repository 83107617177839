.banco_imagens {
    &--button {
        margin-left: 5px;
    }
}

.banco_item {
    margin-bottom: 40px;

        a {
            color: $text-color;

                &:hover {
                    text-decoration: underline;
                }
        }

        &--info {
            margin-top: 20px;
        }

        &--title {
            font-weight: 500;
        }
}

.banco_photo {
    margin-bottom: 30px;

        &--info {
            margin-top: 20px;

                p {
                    margin: 0;
                    padding: 0;
                }
        }

        &--title {
            font-weight: 500;
        }

        &--date {
            font-size: $small-text;
        }
}

.banco_modal {
    max-width: 80%;

            @include media-breakpoint-down(md) {
                margin-top: 20px;
                max-width: 96%;
            }

        .modal-content {
            background-color: transparent;
            border: none;
        }

        .modal-body {
            padding: 0;
            position: relative;
        }

        .close {
            color: $text-color;
            font-size: 26px;
            position: absolute;
            top: -5px;
            right: 10px;
        }

        &--desc,
        &--info {
            background-color: $light-color;
            padding: 30px;
        }

        &--desc {
            min-height: 210px;

                p {
                    font-size: $small-text;
                }
        }

        &--info {
            margin-top: 15px;

                ul {
                    padding: 0;
                }

                li {
                    list-style: none;
                }
        }

        &--infotitle {
            font-weight: bold;
            font-size: $secondary-text;
        }

        &--warning {
            color: red;
        }
}